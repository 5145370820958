<template>
  <a
    :href="openMapPath"
    class="relative rounded-lg py-1 pl-1 pr-4 hover:bg-gray-200 hover:bg-opacity-50 group w-full flex gap-4 items-center text-left"
  >
    <div class="flex-1">
      <Person no-style size="xs" :person="user" paddingless>
        <template v-if="showMapMeta" #secondline>
          <div class="text-sm">Mapa de férias {{ item.year }}</div>
        </template>
      </Person>
    </div>
    <div class="flex gap-2 items-center">
      <b-tooltip
        v-if="
          (item.version && item.status === 'approved') ||
            (item.last_approved_version_number && item.last_approved_version_key != item.key)
        "
        position="is-bottom"
        multilined
        type="is-light"
        label="O mapa de férias tem uma versão aprovada."
        :delay="500"
      >
        <fw-tag type="border-light-primary" size="xs">
          <span class="mr-1 font-semibold">v.{{ item.last_approved_version_number || item.version || 'x' }}</span>
          <span class="flex items-center">
            <fw-icon-checkbox-circle class="text-primary w-4 h-4 flex-shrink-0" />
            <fw-icon-checkbox-circle class="w-4 h-4 flex-shrink-0 text-primary -ml-2 bg-white rounded-full" />
          </span>
        </fw-tag>
      </b-tooltip>
      <b-tooltip
        v-if="
          (!item.last_approved_version_key && item.status !== 'approved') ||
            (item.last_approved_version_key && item.last_approved_version_key != item.key)
        "
        position="is-bottom"
        multilined
        :delay="500"
        type="is-light"
        label="Estado da última versão criada do mapa de férias."
      >
        <fw-tag :type="`${colors[item.status]}`" size="xs">
          <span class="font-semibold">v.{{ item.version || 'next' }}</span>
          <span class="ml-0.5">/ {{ statusLabels[item.status]?.['pt'] }}</span>
        </fw-tag>
      </b-tooltip>
    </div>
    <div class="text-xs flex gap-2 items-center font-semibold">
      <div v-if="!short" class="flex gap-1 min-w-28 text-gray-500">
        <fw-icon-calendar-event class="w-4 h-4 flex-shrink-0" />
        <div title="Dias de férias">{{ item.days_count.selected }} / {{ item.days_count.total }}</div>
        <div v-if="item.days_count.csv_credit > 0" title="Dias de crédito">
          + {{ item.days_count.credit_selected }} / {{ item.days_count.csv_credit }}
        </div>
      </div>
      <div v-if="!short" class="flex gap-1 relative">
        <fw-icon-settings
          class="w-4 h-4 flex-shrink-0"
          :class="item.blocked === true ? 'text-yellow-600' : 'text-gray-300'"
        />
        <span v-if="item.blocked === true" class="-top-1 -right-1.5 absolute">
          <fw-dot color="orange" />
        </span>
      </div>
      <div v-if="!short && item.stats" class="flex gap-1">
        <fw-icon-actions
          class="w-4 h-4 flex-shrink-0"
          :class="item.stats?.pending_actions > 0 ? 'text-yellow-600' : 'text-gray-300'"
        />
      </div>
      <div v-if="!short && item.stats && (userPermissions.isManager || userPermissions.isAdmin)" class="flex gap-1">
        <fw-icon-alert
          class="w-4 h-4 flex-shrink-0"
          :class="item.stats?.errors > 0 ? 'text-yellow-600' : 'text-gray-300'"
        />
      </div>
    </div>
    <div v-if="item.updated_at" class="text-xs text-gray-500 w-36 text-left flex gap-2 items-center">
      <fw-dot :color="item.has_updates ? 'primary' : 'light'" inline :ping="item.has_updates" />
      <span>{{ item.updated_at | formatDateTime }}</span>
    </div>
  </a>
</template>

<script>
import { MAP_USER_STATUS_COLORS, MAP_USER_STATUS, MAP_USER_VERSION_REASONS } from '@/utils/index.js'
import Person from '@/fw-modules/fw-core-vue/ui/components/cards/Person'

export default {
  components: {
    Person
  },

  props: {
    user: {
      type: Object
    },
    item: {
      type: Object,
      required: true
    },
    short: {
      type: Boolean,
      default: false
    },
    showMapMeta: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      versionsMotives: MAP_USER_VERSION_REASONS,
      colors: MAP_USER_STATUS_COLORS,
      statusLabels: MAP_USER_STATUS
    }
  },

  computed: {
    language() {
      return this.$store.state.language
    },

    userPermissions() {
      return this.$store.getters.userPermissions
    },

    openMapPath() {
      return this.$router.resolve({
        name: 'manage-map-user',
        params: {
          year: this.item.year,
          userKey: this.item.user_key,
          mapUserKey: this.item.key,
          key: this.item.map_key
        }
      }).href
    }
  }
}
</script>

<i18n>
{
  "pt": {
    "status": {
      "open": "Aberto",
      "closed": "Submetido",
      "submitted": "Submetido",
      "sealed": "Fechado"
    }
  },
  "en": {
    "status": {
      "open": "Aberto",
      "closed": "Submetido",
      "submitted": "Submetido",
      "sealed": "Fechado"
    }
  }
}
</i18n>
